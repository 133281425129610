import '../css/card.css'
import { useState } from 'react';

import checkMarkImage from '../img/check_mark.png'


function Card ({image, title, promocode, percent, free_spins, link}) {

    const [isClicked, setIsClicked] = useState(true);
    
    let freeSpinsString;

    if (percent !== null && free_spins !== null) {
        freeSpinsString = '+' + percent.toString() + '%  + ' + free_spins.toString() + ' FS' 
    } 
    else if (percent !== null && free_spins === null){
        freeSpinsString = '+' + percent.toString() + '%'
    }
    else if (percent === null && free_spins !== null) {
        freeSpinsString = free_spins.toString() + ' FS'
    }
    else {
        freeSpinsString = ''
    }

    const redirectToGame = (link) => {
        window.open(link, '_blank')
    };

    
    const copyTextToClipboard = (event, text) => {
        event.stopPropagation();
        navigator.clipboard.writeText(text);
        setIsClicked(!isClicked);
    }

    return (
        <div className="card" onClick={() => redirectToGame(link)}>
            <div className="card-image-container">
                <img src={image} alt='error' className="card-image"/>            
            </div>
            <div className="card-text-area">
                {promocode !== null ? (<div className="card-title">{title}</div>) : (<div className="card-title-cor">{title}</div>)}
                { promocode !== null && (
                <div>
                <div className="card-promocode">Промокод:</div>
                <div className="card-promocode-area" onClick={(event) => copyTextToClipboard(event, promocode)}>
                    {isClicked ?  (<div className="promocode-text">{promocode}</div>) : (
                        <div className="promocode-image-container">
                            <img className='promocode-image' alt='error' src={checkMarkImage}/>
                        </div>
                    )}
                </div>
                </div>
                )}
            </div>
            <div className="card-free-spins-area">
                <div className="card-free-spins">{freeSpinsString}</div>
                <div className="card-play-button-container">
                    <div className='card-play-button'>
                        <div className="card-play-button-text">Играть</div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Card;